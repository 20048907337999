import * as Yup from 'yup';

export const emailRegex = /((?:[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*(?:[\w\!\#$\%\'\*\+\-\/\=\?\^\`{\|\}\~]|&amp;)+)@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,63})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)/i;

const GMCSchemaStepOne = Yup.object().shape({
  company: Yup.string()
    .min(3, 'ეს ველი საჭიროა')
    .max(200, 'ეს ველი საჭიროა')
    .required('ეს ველი საჭიროა'),
  phone: Yup.string()
    .min(5, 'ეს ველი საჭიროა')
    .max(20, 'ეს ველი საჭიროა')
    .required('ეს ველი საჭიროა'),
  email: Yup.string()
    .required('ეს ველი საჭიროა')
    .email('Please enter the valid email')
    .matches(emailRegex, 'Please enter the valid email'),

  // location: Yup.string().required('Tell us where you are located'),
  // employees: Yup.string().required('Please select one option'),
});

export default GMCSchemaStepOne;
import React from 'react';
import NavigatorForm from '../components/forms/gmc1';
import {Layout} from "@layout/Layout/Layout";
import GMCLayout from "../layouts/gmc";

const Navigator = (props) => {
    return (
        <Layout {...props}>
            <GMCLayout>
                <NavigatorForm/>
            </GMCLayout>
        </Layout>
    );
};

export default Navigator;
import * as Yup from 'yup';

const GMCSchemaStepTwo = Yup.object().shape({
  // education: Yup.string()
  //   .min(3, 'ეს ველი საჭიროა')
  //   .max(200, 'ეს ველი საჭიროა')
  //   .required('ეს ველი საჭიროა'),
  educationLevel: Yup.string()
  .required('Please select one'),
});

export default GMCSchemaStepTwo;
import React, { useEffect, useState } from "react"
import {
  Box,
  FormControlLabel,
  Typography,
  TextField,
  Autocomplete,
  FormGroup,
  Switch, Button
} from "@mui/material"
import theme from "../../../../styles/theme/theme.const"
import { locations } from "../../../../constants/locations.const"
import whatsAppImg from "../../../../assets/images/whatsapp.svg"
import CircularProgress from "@mui/material/CircularProgress"
import zipData from "../../../../data/USCities.json"
import validationSchema from './validation-scheme'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { RHFTextField } from "../../../hook-form/RHFTextField";
import FormProvider from "../../../hook-form/FormProvider";
import RHFRadioGroup from "../../../hook-form/RHFRadiaButton";
const styles = {
  employees: {
    flexDirection: "row!important"
  },

  whatsappImg: {
    marginRight: 8
  },

  checkboxGroup: {
    "& .MuiFormGroup-root": {
      flexDirection: "row"
    }
  },

  whatsapp: {
    justifyContent: "start"
  },

  checkbox: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "max-content",
    marginRight: 6,

    "& span:first-child": {
      padding: "8px 16px",
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey["400"]}`,
      width: 80,
      height: 33,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "&.Mui-checked": {
        backgroundColor: theme.palette.primary.main,

        "& + span": {
          color: theme.palette.common.white
        }
      },

      "& span": {
        display: "none"
      }
    },
    "& span:last-child": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      whiteSpace: "nowrap"
    }
  },

  zipLoading: {
    position: "absolute",
    right: 20,
    top: "50%",
    transform: "translateY(-50%)",
    display: "flex"
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "25px",
    }
  }
};

const StepOne = ({ data, activeStep, handleBack, handleNext, STEPS }) => {
  console.log("data", data, activeStep, STEPS)
  const [zipLoading, setZipLoading] = useState(false)
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      zip: data?.zip || "",
      city: data?.city || "",
      state: data?.state || "",
      county: data?.county || "",
      phone: data?.phone || "",
      email: data?.email || "",
      company: data?.company || "",
      employees: data?.employees || "",
      whatsapp: data?.whatsapp || false,
      location: data?.location ||'',
    },

  });
  const {
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = watch();
  // console.log(" errors", errors)
  const onSubmit = async (data) => {
    // console.log("data====>", data)
    try {
      handleNext(data)
      // reset();
      // console.log('DATA=====>', data);
    } catch (error) {
      console.error(error);
    }
  };

  const clearZipInputs = () => {
    setValue("city", "")
    setValue("state", "")
    setValue("county", "")
    setZipLoading(false)
  }

  const searchZip = () => {
    if (values?.zip?.length >= 3 && values?.zip?.length <= 5) {
      setZipLoading(true)

      const filteredZip = zipData?.filter((el) => el.zip_code === +values.zip);

      if (!filteredZip?.length) {
        setError("zip", "Zip not found.")
        clearZipInputs()
        return;
      }

      setValue('city', filteredZip[0].city)
      setValue('state', filteredZip[0].state)
      setValue('county', filteredZip[0].county)
      setError('zip', '')
      setZipLoading(false)
      return;
    }

    clearZipInputs()
  }

  useEffect(() => {
    searchZip()
  }, [values.zip])

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography style={{ paddingTop: 10, paddingBottom: 20 }} variant='h3' >მომსახურების მოთხოვნა </Typography>
      <Box display={"flex"} flexDirection={{ xs: "column" }} justifyContent={{ lg: "space-between" }}>
        <Box maxWidth={"100%"}>
          <Box mb={5}>
            <RHFTextField
              // InputProps={{ sx: { borderRadius: 8 } }}
              label="სახელი, გვარი"
              name="company"
            />
          </Box>
          <Box mb={5}>
            <RHFTextField
              // InputProps={{ sx: { borderRadius: 8 } }}
              // Apply the custom style here
              label="ტელ. ნომერი"
              name="phone"


            />
          </Box>
          <Box mb={5}>
            <RHFTextField
              // InputProps={{ sx: { borderRadius: 8 } }}
              // Apply the custom style here
              label="ელ. ფოსტა"
              name="email"
              type={"email"}

            />
          </Box>


          {/* <Box mb={5} position={"relative"}>
            <RHFTextField
              // InputProps={{ sx: { borderRadius: 8 } }}
              // Apply the custom style here
              label="ZIP"
              name="zip"
              type={"text"}
              color={"secondary"}
              fullWidth
              placeholder={"XXXXX"}
              onChange={(event) => {
                const { value } = event.target
                setValue("zip", value.replace(/[^\d{5}]$/, "").substr(0, 5))
              }}
            />
            <Box sx={styles.zipLoading}>
              {zipLoading && <CircularProgress size={20} />}
            </Box>
          </Box>
          <Box mb={5}>
            <TextField
              // InputProps={{ sx: { borderRadius: 8 } }}
              // Apply the custom style here
              label="County"
              name="county"
              type={"text"}
              color={"secondary"}
              value={values.county || ""}
              fullWidth
              disabled
            />
          </Box>
          <Box mb={5}>
            <TextField
              // InputProps={{ sx: { borderRadius: 8 } }}
              // Apply the custom style here
              label="State"
              name="state"
              type={"text"}
              color={"secondary"}
              value={values.state || ""}
              fullWidth
              disabled
            />
          </Box>
          <Box mb={5}>
            <TextField
              // InputProps={{ sx: { borderRadius: 8 } }}
              // Apply the custom style here
              label="City"
              name="city"
              type={"text"}
              color={"secondary"}
              value={values.city || ""}
              fullWidth
              disabled
            />
          </Box> */}


          <Box mb={5}>


            {/* <Autocomplete
              id="location"
              // apply the custom style here
              sx={{
                borderRadius: 8,
              }}
              fullWidth
              freeSolo
              onChange={(e, value) => setValue("location", value)}
              autoComplete={false}
              onFocus={() => setError("location", "")}
              name={"location"}
              value={values.location || ""}
              disableClearable={true}
              options={locations.map((option) => `${option.name} (${option.state})`)}
              renderInput={(params) => {
                return (
                  <TextField
                    // InputProps={{ sx: { borderRadius: 8 } }}
                    label="ინგლისური ენის ცოდნის დონე"
                    type={"text"}
                    color={"secondary"}
                    fullWidth
                    required
                    {...params}
                    error={!!errors.location}
                    helperText={errors.location ? errors.location : ""}
                  />
                )
              }}
            /> */}
              {/* <Controller
                name="location"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  console.log(error);
                  const errMsg = error && 'ეს ველი საჭიროა';

                  return (
                    <Autocomplete
                    value={values.location || ""}

                     // InputProps={{ sx: { borderRadius: 8 } }}
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={locations.map((option) => `${option.name} (${option.state})`)}
                      // getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.name === value.name}
                      renderInput={(params) => (
                        <TextField {...params} error={!!error} helperText={errMsg} label="ინგლისურის ცოდნის დონე" />
                      )}
                    />
                  );
                }}
              /> */}
          </Box>
          <Box mb={5}>
          {/* <Box mb={3}>
                <Typography>Select No. of Employees in your organisation</Typography>
              </Box> */}
            {/* <FormControl
              required={true}
              error={!!errors.employees}
              onChange={handleChange}
              sx={styles.checkboxGroup}
            >
             
              <RadioGroup name="employees" value={values.employees}>
                <FormControlLabel sx={styles.checkbox} value="7-50" control={<Radio />} label={
                  <Typography variant={"caption"}>7-50</Typography>
                } />
                <FormControlLabel sx={styles.checkbox} value="51-200" control={<Radio />} label={
                  <Typography variant={"caption"}>51-200</Typography>
                } />
                <FormControlLabel sx={styles.checkbox} value="200+" control={<Radio />} label={
                  <Typography variant={"caption"}>200+</Typography>
                } />
              </RadioGroup>
              {errors.employees && <FormHelperText>{errors.employees}</FormHelperText>}
            </FormControl> */}
            {/* <RHFRadioGroup
              name="employees"
              control={control}
              rules={{ required: true }}
              error={!!errors.employees}
              label="Select No. of Employees in your organisation"
              options={[
                { label: "7-50", value: "7-50" },
                { label: "51-200", value: "51-200" },
                { label: "200+", value: "200+" },
              ]}
            /> */}
              
          </Box>
          <Box mb={5}>
            {/* <FormGroup>
              <FormControlLabel
                labelPlacement="start"
                name="whatsapp"
                color={"secondary"}
                value={values.whatsapp || true}
                control={<Switch defaultChecked />}
                sx={styles.whatsapp}
                label={
                  <Box display={"flex"} alignItems={"center"}>
                    <img style={styles.whatsappImg} src={whatsAppImg} alt="whatsApp" />
                    <Typography variant={"body2"}>Get WhatsApp updates</Typography>
                  </Box>
                } />
            </FormGroup> */}
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep !== 0 && <Button variant="contained" color="primary" style={{
            borderRadius: 24,
            paddingLeft: 20,
            paddingRight: 20, paddingTop: 10,
            paddingBottom: 10,
            width:"130px"

          }}
            disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            უკან
          </Button>}
          <Button variant="contained" type='submit' color="success" style={{
            borderRadius: 24,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 10,
            paddingBottom: 10,
            width:"130px"

          }}
            // onClick={handleNext}
             sx={{ mr: 1 }}>
            {activeStep === STEPS.length - 1 ? 'Submit' : 'შემდეგი'}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  )
}

export default StepOne
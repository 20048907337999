import React from "react"
import { Box, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material"
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import validationSchema from './validation-scheme'
import FormProvider from "../../../hook-form/FormProvider";


const StepFour = ({ data, handleNext }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...data,
    },

  });
  const {
    watch,
    setValue,
    handleSubmit,
  } = methods;
  // console.log('errors', errors)
  const values = watch();
  // console.log('values===2', values)
  const onSubmit = async (data) => {
    // console.log('data====2>', data)
    try {
      handleNext(data)
      // reset();
    } catch (error) {
      console.error(error);
    }
  };
  const onChangeOptions = (event, newValue) => {
    setValue("qualificationLevel", newValue);
    handleSubmit(onSubmit)(event)

  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography style={{ paddingTop: 10, paddingBottom: 20 }} variant='h3'  >{"მომსახურება:"}</Typography>
      <Box display={"flex"} flexDirection={{ xs: "column" }} justifyContent={{ lg: "space-between" }}>
        <Box maxWidth={"100%"}>
          <Box mb={5}>
            <Box mb={5}>
              <ToggleButtonGroup
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'stretch',

                }}
                size="large"
                color="primary"
                value={values.options || ''}
                exclusive
                onChange={onChangeOptions}
               // {...other}
               >
                {/* <ToggleButton  style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                      border:'none',
                      backgroundColor:"#F2F2F2",
                      borderRadius: "13px",
                      color: "#000000",
                    }} key="Beginner" value="Beginner">
                  <Box
                  style={{
                    paddingTop: "6px",
                    paddingBottom: "6px",

                  }}
                   >
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      სკოლის მოსწავლე
                    </Typography>
                  </Box>
                </ToggleButton>, */}
                <ToggleButton  style={{
                         marginTop: "8px",
                         marginBottom: "8px",
                         border:'none',
                         backgroundColor:"#F2F2F2",
                         borderRadius: "13px",
                         color: "#000000",
                    }} key="Student" value="დიაგნოსტიკა">
                  <Box
                     style={{
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}>
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      დიაგნოსტიკა
                    </Typography>
                  </Box>
                </ToggleButton>,
                <ToggleButton  style={{
                         marginTop: "8px",
                         marginBottom: "8px",
                         border:'none',
                         backgroundColor:"#F2F2F2",
                         borderRadius: "13px",
                         color: "#000000",
                    }}  key="Marketing" value="ელემენტების შეცვლა">
                  <Box
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}>
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      ელემენტების შეცვლა
                    </Typography>
                  </Box>
                </ToggleButton>,
                <ToggleButton style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          border:'none',
                          backgroundColor:"#F2F2F2",
                          borderRadius: "13px",
                          color: "#000000",
                    }}  key="IT" value="გაგრილების სისტემის გაწმენდა">
                  <Box
                     style={{
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}>
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      გაგრილების სისტემის გაწმენდა
                    </Typography>
                  </Box>
                </ToggleButton>,
                <ToggleButton  style={{
                         marginTop: "8px",
                         marginBottom: "8px",
                         border:'none',
                         backgroundColor:"#F2F2F2",
                         borderRadius: "13px",
                         color: "#000000",
                    }}  key="Journalism" value="მუხრუჭების სისტემის გაწმენდა">
                  <Box
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}>
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      მუხრუჭების სისტემის გაწმენდა
                    </Typography>
                  </Box>
                </ToggleButton>,
                <ToggleButton  style={{
                         marginTop: "8px",
                         marginBottom: "8px",
                         border:'none',
                         backgroundColor:"#F2F2F2",
                         borderRadius: "13px",
                         color: "#000000",
                    }}  key="Medicine" value="ელემენტების აღდგენა-შეკეთება">
                  <Box
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}>
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      ელემენტების აღდგენა-შეკეთება
                    </Typography>
                  </Box>
                </ToggleButton>,
                {/* <ToggleButton  style={{
                         marginTop: "8px",
                         marginBottom: "8px",
                         border:'none',
                         backgroundColor:"#F2F2F2",
                         borderRadius: "13px",
                         color: "#000000",
                    }}  key="Finance" value="Finance">
                  <Box
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}>
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      საფინანსო
                    </Typography>
                  </Box>
                </ToggleButton>, */}
                <ToggleButton  style={{
                         marginTop: "8px",
                         marginBottom: "8px",
                         border:'none',
                         backgroundColor:"#F2F2F2",
                         borderRadius: "13px",
                         color: "#000000",
                    }}  key="Other" value="სხვა">
                  <Box
                    style={{
                      paddingTop: "6px",
                      paddingBottom: "6px"
                    }}>
                    <Typography variant="h6" align="center" paragraph style={{ paddingTop: 3 }}>
                      სხვა
                    </Typography>
                  </Box>
                </ToggleButton>,
              </ToggleButtonGroup>
              {/* <FormControl
                required={true}
                error={!!errors.experienceLevel}
                onChange={handleChange}
                sx={styles.checkboxControl}
              >
                <RadioGroup name="experienceLevel" value={values?.experienceLevel}>
                  <FormControlLabel sx={styles.checkbox} value="Beginner" control={<Radio />} label={
                    <Typography variant={"caption"}>Beginner</Typography>
                  } />
                  <FormControlLabel sx={styles.checkbox} value="Low" control={<Radio />} label={
                    <Typography variant={"caption"}>Low</Typography>
                  } />
                  <FormControlLabel sx={styles.checkbox} value="Moderate" control={<Radio />} label={
                    <Typography variant={"caption"}>Moderate</Typography>
                  } />
                  <FormControlLabel sx={styles.checkbox} value="High" control={<Radio />} label={
                    <Typography variant={"caption"}>High</Typography>
                  } />
                </RadioGroup>
                {errors?.experienceLevel && <FormHelperText>{errors?.experienceLevel.message}</FormHelperText>}
              </FormControl> */}
            </Box>
            {/* <FormControl
              required={true}
              error={!!errors.firstTime}
              onChange={handleChange}
              sx={styles.checkboxControl}
            >
              <RadioGroup name="firstTime" value={values.firstTime}>
                <FormControlLabel sx={styles.checkbox} value="Yes" control={<Radio />} label={
                  <Box display={"flex"} alignItems={"center"}>
                    <img src={yesImg} alt="YES" />
                    <Box ml={4}>
                      <Typography>Yes</Typography>
                      <Typography variant={"h4"}>Buying for the first time</Typography>
                    </Box>
                  </Box>
                } />
                <FormControlLabel sx={styles.checkbox} value="No" control={<Radio />} label={
                  <Box display={"flex"} alignItems={"center"}>
                    <img src={noImg} alt="YES" />
                    <Box ml={4}>
                      <Typography>No</Typography>
                      <Typography variant={"h4"}>Existing policy is expiring</Typography>
                    </Box>
                  </Box>
                } />
              </RadioGroup>
              {errors.firstTime && <FormHelperText>{errors.firstTime}</FormHelperText>}
            </FormControl> */}
          </Box>


        </Box>
        {/* <Box sx={{ textAlign: 'center' }}>
          {activeStep !== 0 && <Button variant="contained" color="primary" style={{
            borderRadius: 24,
            paddingLeft: 20,
            paddingRight: 20, paddingTop: 10,
            paddingBottom: 10,
            width: "130px"
          }}
            disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>}
          <Button variant="contained" type='submit' color="success" style={{
            borderRadius: 24,
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 10,
            paddingBottom: 10,
            width: "130px"

          }}
            // onClick={handleNext}
            sx={{ mr: 1 }}>
            {activeStep === STEPS.length - 1 ? 'Submit' : 'Continue'}
          </Button>
        </Box> */}
      </Box>
    </FormProvider>
  )
}

export default StepFour